<template>
    <div class="add_invoice_box d-none">
        <div class="invoice_main">
            <i class="iconfont icon-icon_x close_icon" @click="cancel()"></i>

            <div class="title">发票信息</div>
            <div class="warm_prompt">
                温馨提示：请确保所填开票信息与贵公司税务登记证一致。
            </div>

            <el-form
                :model="form"
                ref="form_invoice"
                :rules="invoice_rules"
                label-width="90px"
            >
                <el-form-item label="公司名称:" prop="company">
                <el-input v-model="form.company"></el-input>
                </el-form-item>

                <el-form-item label="公司税号:" prop="tax_number">
                <el-input v-model="form.tax_number"></el-input>
                </el-form-item>

                <el-form-item label="单位地址:">
                <el-input v-model="form.address"></el-input>
                </el-form-item>

                <el-form-item label="电话号码:">
                <el-input v-model="form.tel"></el-input>
                </el-form-item>

                <el-form-item label="开户银行:">
                <el-input v-model="form.deposit_bank"></el-input>
                </el-form-item>

                <el-form-item label="银行账号:">
                <el-input v-model="form.bank_account"></el-input>
                </el-form-item>

                <el-form-item label="电子邮箱:" prop="email">
                <el-input v-model="form.email"></el-input>
                </el-form-item>

                <div class="confirm_btns">
                    <el-button @click="cancel()">取消</el-button>
                    <el-button @click="resetForm()">重置</el-button>

                    <el-button class="sure" type="primary" @click="submitForm()">
                        保存
                    </el-button>
                </div>
            </el-form>
        </div>
    </div>
</template>

<script>
import { postData } from "@/api/user";
import { ElMessage } from "element-plus"; 
import {toRefs,reactive,defineComponent,ref } from 'vue'


export default defineComponent({
    name: "add_invoice",
    props:{
        type:String,
        info:String
    },
    emits:['changeModal'],

    setup(props,ctx) {
        const adr_type =reactive(props.type)
        const adr_info = reactive(props.info)
        const form_invoice = ref(null)

        var checkDuty = (rule, value, callback) => {
            if (!value) {
                return callback(new Error("请输入公司税号"));
            }
            const duty = /^[A-Za-z0-9]+$/;
            if (duty.test(value)) {
                callback();
            } else {
                return callback(new Error("公司税号税号只能是数字和字母！"));
            }
        };

        var checkEmail = (rule, value, callback) => {
            if (!value) {
                return callback(new Error("请输入邮箱地址"));
            }
            let reg = /^\w+@[a-zA-Z0-9]{2,10}(?:\.[a-z]{2,4}){1,3}$/;
            if (reg.test(value)) {
                callback();
            } else {
                return callback(new Error("请输入正确的邮箱！"));
            }
        }

        let state = reactive({
            defalut_arrdess: true, // 默认地址
            invoice_rules: {
                company: [{ required: true, message: "请输入公司名称", trigger: "blur" }],
                tax_number: [{ required: true, validator: checkDuty, trigger: "blur" }],
                email: [{ required: true, validator: checkEmail, trigger: "blur" }],
            },
            form: {
                company: "",
                tax_number: "",
                address: "",
                tel: "",
                deposit_bank: "",
                bank_account: "",
                email: "",
            }
        })

        let init = () => {
            if (adr_info && adr_type == "edit") {
                state.form = adr_info
            }
        }
        init()

        let submitForm = () => {
            form_invoice.value.validate((valid) => {
                if (valid) {
                    let url = `api/invoices`;
                    let params = {
                        company: state.form.company,
                        tax_number: state.form.tax_number,
                        address: state.form.address,
                        tel: state.form.tel,
                        deposit_bank: state.form.deposit_bank,
                        bank_account: state.form.bank_account,
                        email: state.form.email,
                    };
                    postData({
                        url: url,
                        data: params,
                    }).then((res) => {
                        let data = res.data;
                        if (data.code === 200) {
                            ElMessage.success(data.data.msg);
                            cancel();
                        } else if (data.code === 422) {
                            ElMessage.error(data.data.msg);
                        }
                    });
                } else {
                    return false;
                }
            });
        }

        // 关闭弹框
        let cancel = () => {
            ctx.emit("changeModal", false);
        }

        // 重置
        let resetForm = () => {
            form_invoice.value.resetFields();
            state.form = {};
        }

        return {
            ...toRefs(state),
            form_invoice,
            resetForm,
            cancel,
            submitForm,
            adr_info,
            adr_type

        }

    }
})
</script>

<style lang="less">
.add_invoice_box {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.35);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 998;

    .invoice_main {
        position: relative;
        padding: 0 24px;
        border-radius: 8px;
        width: 426px;
        background: #FFFFFF;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);

        .close_icon {
            position: absolute;
            top: 14px;
            right: 16px;
            font-size: 10px;
            color: #666;
            cursor: pointer;
        }

        .title {
            font-size: 16px;
            font-weight: 600;
            line-height: 22px;
            color: #333333;
            padding: 18px 0 24px;

        }

        .warm_prompt {
            margin-bottom: 24px;
            font-size: 12px;
            font-weight: 600;
            color: #ffb34b;
            line-height: 1;
        }
    }
    .el-form-item {
        margin-bottom: 20px;

        .el-form-item__label {
            color: #666;
            font-size: 13px;
            text-align: right;
            padding-right: 16px;
        }

        .el-input__inner {
            height: 32px;
            background: #FFFFFF;
            border: 1px solid #D1D1D1;
            border-radius: none!important;
            font-size: 13px;
            font-weight: 400;
            line-height: 18px;
            color: #333333;
            padding: 7px 16px;
        }
        .el-select {
            width: 100%;
        }
        .el-textarea__inner {
            width: 294px;
            height: 79px;
            background: #FFFFFF;
            border: 1px solid #D1D1D1;
            resize: none;
            font-size: 13px;
            font-weight: 400;
            line-height: 18px;
            color: #333333;
            padding: 7px 16px;
        }
    }

    .confirm_btns {
        text-align: right;
        padding: 18px 0;

        .el-button {
            width: 68px;
            height: 32px;
            border: 1px solid #ddd;
            border-radius: 3px;
            font-size: 14px;
            line-height: 32px;
            color: #666666;
            padding: 0;
            background: #FAFAFA;

        }
        .sure {
            background: #1C46B7;
            color: #fff;
            border: 1px solid #1C46B7;
        }
    }
}
</style>
