<!--购物车结算-->
<template>
<div class="pay"></div>
</template>

<script>
import topnav from "@/components/Nav.vue";
import foot from "@/components/Footer.vue";
import addaddress from "@/components/AddAddress.vue";
import addinvoice from "@/components/AddInvoice.vue";
import { getData } from "@/api/user";

import { toRefs, reactive } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

// import { ElMessage } from 'element-plus'

export default {
    name: "qq",
    setup() {
        const store = useStore()
        const route = useRoute()

        let state = reactive({
            userid: store.state.userInfo.id,
        });

        let init = () => {
            getData({
                url: `api/login/qqcb?code=${route.query.code}&state=${route.query.state}`,
                data: {},
            }).then((res) => {
                let data = res.data;
                if (data.code === 200) {
                    let info = data.data.result;
                    if (info.verification === false) {
                        opener.location.reload();
                        window.close();
                        opener.location.href=`https://chip.ebaina.com/register?openid=${info.token}&name=${info.nickname}`
                    } else {
                        store.dispatch('handleLogin', info)
                        window.close();
                        opener.location.reload();
                    }

                    

                }
            });
        }   
        init()

        return {
            ...toRefs(state),
        }
    },
    components: {
        topnav,
        foot,
        addaddress,
        addinvoice,
    }
};

</script>


